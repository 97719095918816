.App {
  text-align: center;
  scrollbar-width: thin;
}

hr.small-separator {
  height: 1px;
  border: none;
  background-color: #efefef;
  width: 80px;
}

div::-webkit-scrollbar {
  width: 10px;
}

div::-webkit-scrollbar-track {
  background-color: rgb(223, 223, 223);
  border-radius: 3px;
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.288);
  border-radius: 3px;
}

/* Custom theme */
.MuiButton-root {
  border-radius: 4px !important;
}
