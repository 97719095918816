.thin-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(148, 148, 148); 
  border-radius: 2px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(184, 184, 184); 
  border-radius: 2px;
}
